<template>
    <div class="container-fluid p-2">
        <b-card>
            <template #header>
                <h6 class="mb-0">Setting PSB</h6>
            </template>
            <b-button variant="outline-success" @click="refresh" size="sm" class="ml-3 mr-2">Refresh</b-button>
            <b-button variant="primary" @click="update" size="sm" v-show="$globalData.LevelLogin == '9'">Update
            </b-button>
            <hr />

            <div class=" col-md-12">
                Info:
                <b-form-textarea v-model="form.pengumuman1" class="mb-2" rows="6" />
                Gelombang:
                <b-form-input v-model="form.gelombang" class="col-md-1 mb-2" size="sm" />
                Biaya Pendaftaran:
                <b-form-input v-model="form.biayapendaftaran" class="col-md-3 text-right mb-2" size="sm"
                    placeholder="biaya pendaftaran " />
                Biaya Pendaftaran Alumni:
                <b-form-input v-model="form.biayapendaftaranalumni" class="col-md-3 text-right mb-2"
                    placeholder="gelombang 1,2,3" size="sm" />
                Tanggal Test
                <b-form-input v-model="form.tanggaltest" class="col-md-3 mb-2" placeholder="gelombang 1,2,3"
                    size="sm" />
                Hari Test
                <b-form-input v-model="form.haritest" class="col-md-3 mb-2" placeholder="gelombang 1,2,3" size="sm" />
                NoVA SMP
                <b-form-input v-model="form.novasmp" class="col-md-3 mb-2" placeholder="No Va SMP" size="sm" />
                NoVA SMA
                <b-form-input v-model="form.novasma" class="col-md-3 mb-2" placeholder="No Va SMA" size="sm" />
            </div>
            <hr />
            Set Initial No.VA: inisial awal dari NoVA (3digit terakhir)
            <b-input-group prepend="NoVA SMP" class="mt-3 ml-1 col-3" size="sm">
                <b-form-input v-model="initsmp"></b-form-input>
                <b-input-group-append>
                    <b-button variant="outline-success" @click="init('P', initsmp)">Init</b-button>
                </b-input-group-append>
            </b-input-group>
            <b-input-group prepend="NoVA SMA" class="mt-3 ml-1 col-3" size="sm">
                <b-form-input v-model="initsma"></b-form-input>
                <b-input-group-append>
                    <b-button variant="outline-success" @click="init('A', initsma)">Init</b-button>
                </b-input-group-append>
            </b-input-group>

        </b-card>

    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'Settingpsb',
    data() {
        return {
            form: {
                pengumuman1: '',
                pengumuman2: '',
                gelombang: '1',
                biayapendaftaran: '',
                biayapendaftaranalumni: '',
                haritest: '',
                tanggaltest: '',
                novasmp: '',
                novasma: '',

            },
            initsmp: '1',
            initsma: '1',

        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            axios
                .get(
                    this.$appUrl + "getsettingpsb.php"
                )
                .then((res) => {
                    this.form = res.data[0];
                })
        },
        init(para, no) {
            axios
                .get(this.$appUrl + "initnova.php?jenis=" + para + "&nova=" + no)
        },
        update() {
            axios
                .post(
                    this.$appUrl + "updatesettingpsb.php", this.form
                )
                .then((res) => {
                    console.log('data update', res.data)
                })
        }
    }
};
</script>