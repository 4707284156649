<template>
    <div class="container-fluid p-2">

        <b-card class="mt-2">
            <template #header>
                <h4 class="mb-0">Admin PSB Module</h4>
            </template>
            <b-button-toolbar size="sm">
                <b-button-group class=" mx-1">
                    <b-button @click="menu('/datapsb')" class="mr-1"
                        :variant="menupil === '/datapsb' ? 'outline-primary' : 'secondary'">Data PSB</b-button>
                    <b-button @click="menu('/settingpsb')" class="mr-1"
                        :variant="menupil === '/settingpsb' ? 'outline-primary' : 'secondary'">Setting PSB</b-button>
                    <b-button @click="menu('/uploadkelulusan')"
                        :variant="menupil === '/uploadkelulusan' ? 'outline-primary' : 'secondary'">Upload
                        Kelulusan</b-button>
                    <!-- <b-button>Undo</b-button> -->
                </b-button-group>
            </b-button-toolbar>
            <hr />
            <router-view></router-view>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Adminpsb",
    components: {
        //  Navbar,
    },
    data() {
        return {
            menupil: '',
            summary: [],
            summary1: [],
            summary2: [],
        };
    },
    mounted() {
        // this.refresh();
        // this.tg1 = this.$func.tglhariini();
        // this.tg2 = this.$func.tglhariini();

    },
    methods: {
        menu(para) {
            console.log("menu", "/adminpsb" + para)
            if (this.menupil != para) {
                this.menupil = para
                this.$router.push({ path: "/adminpsb" + para });
            }
        },
        refresh() {
            axios
                .get(
                    this.$appUrl + "getresumepsb.php?jenis=1"
                )
                .then((res) => {
                    this.summary = res.data.summary;
                    this.summary1 = res.data.data1;
                    // this.summary2 = res.data.data2;

                    // console.log('summary', this.summary)
                });
        },
    },
};
</script>

<style></style>
