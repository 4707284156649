<!-- Profile2  diambil dari profile, digunakan untuk insert/update userid -->
<!-- level login:
{ value : 1, text : '1 waliasrama/pmb'},
{ value : 2, text : '2 KomDis'},
{ value : 3, text : '3 Kesehatan'},
{ value : 4, text : '4 Konseling/BP'},
{ value : 5, text : '5 Perizinan'},
{ value : 6, text : '6 Satpam'},
{ value : 7, text : '7 keuangan'},
{ value : 8, text : '8 adminpsb'},
{ value : 9, text : '7 keuangan'},
{ value : A, text : 'A Admin Monitor Walas & PMB'},
{ value : 9, text : '9 Admin'},]" -->
<template>
  <b-modal title="Profile User" no-close-on-backdrop :ok-only="true" @hidden="reset" :visible="visible" hide-footer>
    <!-- <b-modal
    id="mymodal"
    v-model="modallocal"
    :ok-only="true"
    centered
    hide-header
    hide-footer
    @hide="$emit('close')"
    no-close-on-backdrop
    class="p-0"
  > -->
    <b-card class="col-md px-0 mt-0 mb-0 text-center shadow">
      <input type="file" style="display: none" ref="fileInput" @change="handleFileUpload" />
      <!-- foto:{{ this.$globalData.datauser.pasfoto }} -->
      <!-- {{ profileImages }} | {{ profileImage }} -->

      <div class="col text-left position-absolute">
        <!-- data:
          {{ listhak }} -->
        <span v-for="(detail, k) in listhak" :key="k" style="font-size: 12px">
          <i class="bi bi-house-heart" v-show="detail.noasrama.substr(0, 3) == 'ASS'" />
          <i class="bi bi-house-check" v-show="detail.noasrama.substr(0, 3) == 'KEL'" />
          <i class="bi bi-bicycle" v-show="detail.noasrama.substr(0, 3) == 'PMB'"></i>{{ detail.namaasrama
          }}<br /></span>
      </div>
      <b-img :src="profileImages" @click="openFileInput" rounded width="130%" class="mt-2 mb-2 float-right"
        title="Click untuk Ganti Foto" />
      <b-form>
        <b-input-group size="md">
          <b-input-group-append is-text>
            <i class="bi bi-person" style="font-size: 11px" title="Nama Userid"></i>
          </b-input-group-append>
          <b-form-input v-model="form.namauser" placeholder="nama user" size="sm" :disabled="!isnew" />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-key" style="font-size: 11px"></i>
          </b-input-group-append>
          <b-form-input v-model="form.password" typex="password" placeholder="password" size="sm" />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-person-check" style="font-size: 11px" title="Nama Lengkap"></i>
          </b-input-group-append>
          <b-form-input v-model="form.namawali" placeholder="Nama Lengkap" size="sm" />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-house-check" style="font-size: 11px" title="Alamat Lengkap"></i>
          </b-input-group-append>
          <b-form-textarea v-model="form.alamat" placeholder="Alamat" size="sm" />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-house" style="font-size: 11px" title="Nama Kota"></i>
          </b-input-group-append>
          <b-form-input v-model="form.kota" placeholder="Nama Kota" size="sm" />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-whatsapp" style="font-size: 11px" title="No HP/WA"></i>
          </b-input-group-append>
          <b-form-input v-model="form.nohp" placeholder="No HP/WA" size="sm" />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-bar-chart-steps" style="font-size: 11px" title="Level User"></i></b-input-group-append>
          <b-form-select v-model="form.level" :options="[
            { value: '1', text: '1 waliasrama/pmb' },
            { value: '2', text: '2 KomDis' },
            { value: '3', text: '3 Kesehatan' },
            { value: '4', text: '4 Konseling/BP' },
            { value: '5', text: '5 Perizinan' },
            { value: '6', text: '6 Satpam' },
            { value: '7', text: '7 Keuangan' },
            { value: '8', text: '8 Admin PSB' },
            { value: 'A', text: 'A Admin Monitor Walas & PSB' },
            { value: '9', text: '9 Admin' },
          ]" type="tel" placeholder="Level User" size="sm" />
        </b-input-group>
        <!-- Tanggal:{{ tgl1 }}
        <b-form-input
          type="month"
          placeholder="Telpon/HP/WA"
          size="sm"
          v-model="tgl1"
        /> -->
      </b-form>
      <b-button size="sm" class="mt-3 mr-2" variant="outline-primary" @click="adddata">
        <i class="bi bi-plus mr-2"></i>Add
      </b-button>
      <b-button class="mt-3 mr-3" variant="outline-success" size="sm" @click="updateprofile"><i
          class="bi bi-floppy mr-2"></i>{{ isnew ? "Save" : "Update" }}</b-button>
    </b-card>
  </b-modal>
</template>

<script>
import axios from "axios";

export default {
  name: "Profile2",
  model: {
    prop: "showmodal",
    event: "input",
  },

  props: ["form", "showmodal", "listhak"],
  components: {
    //  Navbar,
  },
  data() {
    return {
      // form: {
      //   userid: "",
      //   password: "",
      //   namawali: "",
      //   alamat: "",
      //   kota: "",
      //   nohp: "",
      // },
      password: "",
      ketlogin: "",
      profileImage: "",
      tgl1: "",
      nf: null,
      isnew: false,
      // modallocal: false,
    };
  },
  // computed: {
  //   modallocal() {
  //     return this.showmodal;
  //   },
  // },
  computed: {
    visible() {
      // return this.item !== null;

      return this.showmodal;
    },
    profileImages() {
      let tg = new Date();
      let ret1 =
        this.form.pasfoto == null
          ? this.$appUrl + "foto/avatar.png"
          : this.$appUrl + this.form.pasfoto + "?d=" + tg;
      if (this.profileImage != "") {
        return this.profileImage;
      } else {
        return ret1;
      }
    },
  },
  mounted() {
    // let tg = new Date();
    // this.profileImage =
    //   this.$globalData.datauser.pasfoto == "" ||
    //   this.$globalData.datauser.pasfoto == null
    //     ? this.$appUrl + "foto/avatar.png"
    //     : this.$appUrl + this.$globalData.datauser.pasfoto + "?d=" + tg;
  },
  watch: {
    // "$globalData.datauser.pasfoto": function (val) {
    //   let tg = new Date();
    //   this.profileImage =
    //     this.$globalData.datauser.pasfoto == "" ||
    //     this.$globalData.datauser.pasfoto == null
    //       ? this.$appUrl + "foto/avatar.png"
    //       : this.$appUrl + val + "?d=" + tg;
    // },
    showmodal() {
      this.isnew = false;
    },
  },
  methods: {
    adddata() {
      this.isnew = true;
    },
    openFileInput() {
      // Klik input file saat gambar diklik
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const uploadedFile = event.target.files[0];
      // Gunakan URL.createObjectURL() untuk menampilkan gambar yang dipilih oleh pengguna secara langsung
      this.profileImage = URL.createObjectURL(uploadedFile);
      // console.log("Profile Image", this.profileImage);
      this.uploadfile(uploadedFile);
    },
    reset() {
      console.log("keluar dari Modal");
      this.$emit("input", null);
    },
    updateprofile() {
      axios
        .post(
          this.$appUrl +
          "changepassword.php?isnew=" +
          this.isnew +
          "&token=" +
          this.$globalData.Token,
          {
            data: this.form,
          }
        )
        .then((res) => {
          console.log("update profile", res.data);
          if (res.data == "Sukses") {
            this.$globalData.datauser = this.form;
            this.$bvModal.msgBoxOk("Data Update Sukses...");
            this.$emit("input", null);
          }
        });
    },
    uploadfile(nf) {
      // console.log("uploadfile", nf, th, index);
      //   this.valuedownload = 0;
      var config = {
        onUploadProgress: (progressEvent) => {
          // ini harus menggunakan arrow function
          console.log("progress event", progressEvent);
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.valuedownload = percentCompleted;
          console.log("progress", percentCompleted, this.valuedownload);
        },
        headers: {
          "Content-Type": "multipart/header-data",
        },
      };
      var data = new FormData();
      data.append("userid", this.form.namauser);
      data.append("file", nf);
      axios
        .post(
          this.$appUrl + "uploadprofile.php?token=" + this.$globalData.Token,
          data,
          config
        )
        .then((res) => {
          //  th.target.innerText = res.data.status;
          this.dok = null;
          this.$toast.success(res.data.status, {
            type: "success",
            position: "top-right",
            duration: 3000,
            dismissible: true,
          }); //   output.className = 'container';
          //   output.innerHTML = res.data;
          // let tg = new Date();
          // this.$globalData.datauser.pasfoto =
          //   this.$globalData.datauser.pasfoto + "?d=" + tg;
          this.profileImage = "";
        })
        .catch(function (err) {
          console.log("error", err);
          //   output.className = 'container text-danger';
          //   output.innerHTML = err.message;
        });
    },
  },
};
</script>

<style></style>
