<template>
    <div class="container-fluid">
        <b-card>
            <template #header>
                <h6 class="mb-0">Upload Kelulusan PSB</h6>
            </template>
            <div class="row">
                <div class="col-md-4">
                    <b-form-textarea v-model='data' rows="17" class="pr-4 mb-2" />
                    <b-button size="sm" @click="getdata">Cek Data</b-button>
                    <b-button size="sm" @click="setkelulusan" class="ml-2" :disabled="result.length == 0">Set
                        Kelulusan</b-button>
                </div>

                <div class="col-md">
                    <b-table :items="result" :fields="['no', 'noregist', 'nama', 'asalsekolah', 'nis', 'lulus']" small
                        style="font-size: 13px;" :busy="isbusy">
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>
                        <template #cell(no)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </div>
            </div>

        </b-card>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Uploadkelulusan',
    data() {
        return {
            data: '',
            dataArray: [],
            result: [],
            isbusy: false,
        }
    },
    methods: {
        cari(kode) {
            // console.log('cari', kode)
            const result = this.dataArray.find(item => item.kode === kode);
            return result ? result.nova : null; // Jika ditemukan, return nova, jika tidak return null
        },

        getdata() {
            let rows = this.data.split('\n');
            this.result = []
            this.isbusy = true
            // Mengubah setiap baris menjadi objek {kode, nova}
            this.dataArray = rows.map(row => {
                let [kode, nova] = row.split('\t'); // Pisahkan kolom dengan tab
                return { kode, nova };
            });

            // console.log(this.dataArray);
            axios
                .post(
                    this.$appUrl + "cekkelulusan.php?token=" + this.$globalData.Token,
                    this.dataArray
                )
                .then((res) => {
                    this.result = res.data;
                    this.result.forEach(element => {
                        // console.log('element', element)
                        element.nis = this.cari(element.noregist)
                    });
                    this.isbusy = false


                });
        },
        setkelulusan() {
            axios
                .post(
                    this.$appUrl + "setkelulusan.php?token=" + this.$globalData.Token,
                    this.dataArray
                )
                .then((res) => {
                    this.$bvModal.msgBoxOk("Set kelulusan done " + res.data)
                });




        },
    }
};
</script>