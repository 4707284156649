<template>
  <b-modal title="Profile User" no-close-on-backdrop :ok-only="true" @hidden="reset" :visible="visible" hide-footer>
    <!-- <b-modal
    id="mymodal"
    v-model="modallocal"
    :ok-only="true"
    centered
    hide-header
    hide-footer
    @hide="$emit('close')"
    no-close-on-backdrop
    class="p-0"
  > -->
    <b-card class="col-md px-0 mt-0 mb-0 text-center shadow">
      <input type="file" style="display: none" ref="fileInput" @change="handleFileUpload" />
      <!-- foto:{{ this.$globalData.datauser.pasfoto }} -->
      <!-- :src="profileImages" -->

      <b-img :src="profileImage" @click="openFileInput" rounded width="130%" class="mt-2 mb-2"
        title="Click untuk Ganti Foto" />
      <!-- foto:{{ $globalData.pasfoto }} -->
      <!-- { "namauser": "yoyo", "password": "pwd123", "namawali": "yoyo", "alamat": "Mutiara Depok B-12", "nohp": 8151633007, "identify": 1, 
        "pasfoto": "foto/pasfoto-yoyo.jpg", "token": "", "level": 9, "jenis": "U", "kodewali": null } -->
      <b-form>
        <b-input-group size="md">
          <b-input-group-append is-text>
            <i class="bi bi-person" style="font-size: 11px" title="Nama Userid"></i>
          </b-input-group-append>
          <b-form-input v-model="form.namauser" placeholder="nama user" size="sm" disabled />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-key" style="font-size: 11px"></i>
          </b-input-group-append>
          <b-form-input v-model="form.password" typex="password" placeholder="password" size="sm" />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-person-check" style="font-size: 11px" title="Nama Lengkap"></i>
          </b-input-group-append>
          <b-form-input v-model="form.namawali" placeholder="Nama Lengkap" size="sm" />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-house-check" style="font-size: 11px" title="Alamat Lengkap"></i>
          </b-input-group-append>
          <b-form-textarea v-model="form.alamat" placeholder="Alamat" size="sm" />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-house" style="font-size: 11px" title="Nama Kota"></i>
          </b-input-group-append>
          <b-form-input v-model="form.kota" placeholder="Nama Kota" size="sm" />
        </b-input-group>
        <b-input-group size="md" class="mt-2">
          <b-input-group-append is-text>
            <i class="bi bi-whatsapp" style="font-size: 11px" title="No HP/WA"></i>
          </b-input-group-append>
          <b-form-input v-model="form.nohp" type="tel" placeholder="Telpon/HP/WA" size="sm" />
        </b-input-group>
        <!-- Tanggal:{{ tgl1 }}
        <b-form-input
          type="month"
          placeholder="Telpon/HP/WA"
          size="sm"
          v-model="tgl1"
        /> -->
      </b-form>
      <b-button class="mt-3 mr-3" variant="outline-success" size="sm" @click="updateprofile"><i
          class="bi bi-floppy mr-2"></i>Update</b-button>
    </b-card>
  </b-modal>
</template>

<script>
import axios from "axios";

export default {
  name: "Profile",
  model: {
    prop: "showmodal",
    event: "input",
  },

  props: ["form", "showmodal"],
  components: {
    //  Navbar,
  },
  data() {
    return {
      // form: {
      //   userid: "",
      //   password: "",
      //   namawali: "",
      //   alamat: "",
      //   kota: "",
      //   nohp: "",
      // },
      password: "",
      ketlogin: "",
      profileImage: "",
      tgl1: "",
      nf: null,
      // modallocal: false,
    };
  },
  // computed: {
  //   modallocal() {
  //     return this.showmodal;
  //   },
  // },
  computed: {
    visible() {
      // return this.item !== null;
      return this.showmodal;
    },
    profileImages() {
      let tg = new Date();
      return this.$globalData.datauser.pasfoto == "" ||
        this.$globalData.datauser.pasfoto == null
        ? this.$appUrl + "foto/avatar.png"
        : this.$appUrl + this.$globalData.datauser.pasfoto + "?d=" + tg;
    },
  },
  mounted() {
    let tg = new Date();
    this.profileImage =
      this.$globalData.datauser.pasfoto == "" ||
        this.$globalData.datauser.pasfoto == null
        ? this.$appUrl + "foto/avatar.png"
        : this.$appUrl + this.$globalData.datauser.pasfoto + "?d=" + tg;
  },
  watch: {
    "$globalData.datauser.pasfoto": function (val) {
      let tg = new Date();
      this.profileImage =
        this.$globalData.datauser.pasfoto == "" ||
          this.$globalData.datauser.pasfoto == null
          ? this.$appUrl + "foto/avatar.png"
          : this.$appUrl + val + "?d=" + tg;
    },
  },
  methods: {
    openFileInput() {
      // Klik input file saat gambar diklik
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const uploadedFile = event.target.files[0];
      // Gunakan URL.createObjectURL() untuk menampilkan gambar yang dipilih oleh pengguna secara langsung
      this.profileImage = URL.createObjectURL(uploadedFile);
      // console.log("Profile Image", this.profileImage);
      this.uploadfile(uploadedFile);
    },
    reset() {
      console.log("keluar dari Modal");
      this.$emit("input", null);
    },
    updateprofile() {
      axios
        .post(
          this.$appUrl + "changepassword.php?token=" + this.$globalData.Token + "&isnew=false",
          { data: this.form }
        )
        .then((res) => {
          console.log("update profile", res.data);
          if (res.data == "Sukses") {
            this.$globalData.datauser = this.form;
            this.$bvModal.msgBoxOk("Data Update Sukses...");
            this.$emit("input", null);
          }
        });
    },
    uploadfile(nf) {
      // console.log("uploadfile", nf, th, index);
      //   this.valuedownload = 0;
      var config = {
        onUploadProgress: (progressEvent) => {
          // ini harus menggunakan arrow function
          console.log("progress event", progressEvent);
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          this.valuedownload = percentCompleted;
          console.log("progress", percentCompleted, this.valuedownload);
        },
        headers: {
          "Content-Type": "multipart/header-data",
        },
      };
      var data = new FormData();
      data.append("userid", this.$globalData.UserId);
      data.append("file", nf);
      axios
        .post(
          this.$appUrl + "uploadprofile.php?token=" + this.$globalData.Token,
          data,
          config
        )
        .then((res) => {
          //  th.target.innerText = res.data.status;
          this.dok = null;
          this.$toast.success(res.data.status, {
            type: "success",
            position: "top-right",
            duration: 3000,
            dismissible: true,
          }); //   output.className = 'container';
          //   output.innerHTML = res.data;
          let tg = new Date();
          this.$globalData.datauser.pasfoto =
            this.$globalData.datauser.pasfoto + "?d=" + tg;
        })
        .catch(function (err) {
          console.log("error", err);
          //   output.className = 'container text-danger';
          //   output.innerHTML = err.message;
        });
    },
  },
};
</script>

<style></style>
