<template>
    <div class="container-fluid p-2">
        <!-- <b-card>
            <template #header>
                <h4 class="mb-0">Admin PSB</h4>
            </template> -->
        <h4>Resume Pendaftar</h4>
        <b-table small :items="summary" class="col-md-3" style="font-size: 13px;" :busy="isBusy">
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>
        </b-table>
        <b-button size="sm" @click="refresh" class="mb-2">Refresh</b-button>
        <b-card>
            <template #header>
                <h6 class="mb-0">Data PSB</h6>
            </template>
            <div class="row mb-3">
                <div class="col-md-3">
                    <b-form-input size="sm" v-model="filter" placeholder="Type to Search"></b-form-input>
                </div>
                <div class="col">
                    <b-form-checkbox-group v-model="filterOn" class="mt-1">
                        Filter ON:
                        <b-form-checkbox value="nama">Nama</b-form-checkbox>
                        <b-form-checkbox value="userid">Userid</b-form-checkbox>
                        <b-form-checkbox value="asalsekolah">Asal Sekolah</b-form-checkbox>
                        <b-form-checkbox value="status">Status Approve</b-form-checkbox>
                        #{{ filteredCount }}
                    </b-form-checkbox-group>
                </div>
            </div>
            <!-- Filter: {{ filterOn }} -->

            <b-table small :items="summary1" :fields="['no', 'userid', 'password', { key: 'nova', sortable: true },
                { key: 'status', label: 'Approve', sortable: true, label: 'Approve' }, { key: 'nama', sortable: true }, 'asalsekolah', 'nohp', 'noreg',
                { key: 'alumni', sortable: true },
                'foto', 'kk', 'akte', 'ktpa', 'ktpi', 'wa', 'act', 'show']" style="font-size: 13px;" responsive
                bordered :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                :filter-included-fields="filterOn" @filtered="onFiltered" hover>


                <template #cell(no)="row">
                    {{ row.index + 1 }}
                </template>
                <template #cell(status)="row">
                    <b-button size="sm" class="col" @click="approvestatus(row.item)">{{ row.item.status == '1' ?
                        'Approve' : 'Unapprove' }}</b-button>
                </template>
                <template #cell(noreg)="row">
                    <div class="text-center">
                        {{ row.item.noreg }}
                    </div>
                </template>
                <template #cell(foto)="row">
                    <div class="text-center">
                        <b-icon-image v-show="row.item.pasfoto != null" @click="showimage(row.item.pasfoto)"
                            style="cursor: pointer;" />
                        <b-icon-file-x v-show="row.item.pasfoto == null" />
                    </div>
                </template>
                <template #cell(kk)="row">
                    <div class="text-center">
                        <b-icon-image v-show="row.item.kk != null" @click="showimage(row.item.kk)"
                            style="cursor: pointer;" />
                        <b-icon-file-x v-show="row.item.kk == null" />
                    </div>
                </template>
                <template #cell(akte)="row">
                    <div class="text-center">
                        <b-icon-image v-show="row.item.akte != null" @click="showimage(row.item.akte)"
                            style="cursor: pointer;" />
                        <b-icon-file-x v-show="row.item.akte == null" />

                    </div>
                </template>
                <template #cell(ktpa)="row">
                    <div class="text-center">
                        <b-icon-image v-show="row.item.ktpa != null" @click="showimage(row.item.ktpa)"
                            style="cursor: pointer;" />
                        <b-icon-file-x v-show="row.item.ktpa == null" />

                    </div>
                </template>
                <template #cell(ktpi)="row">
                    <div class="text-center">
                        <b-icon-image v-show="row.item.ktpi != null" @click="showimage(row.item.ktpi)"
                            style="cursor: pointer;" />
                        <b-icon-file-x v-show="row.item.ktpi == null" />

                    </div>
                </template>
                <template #cell(wa)="row">
                    <div class="text-center">
                        <b-icon-check2-circle v-show="row.item.statuswa && row.item.statuswa.includes('Success')" />
                    </div>
                </template>
                <template #cell(act)="row">
                    <div class="text-center">
                        <b-icon-trash v-show="row.item.status == '1'" @click="hapus(row.item.userid)"
                            style="cursor: pointer;" />
                    </div>
                </template>
                <template #cell(alumni)="row">
                    <div class="text-center">
                        <b-form-checkbox v-model="row.item.konfirmalumni" v-show="row.item.alumni == '1'" value="1"
                            unchecked-value="0" @change="konfirmalumni(row.item)" style="cursor: pointer;" />
                    </div>
                </template>
                <template #cell(show)="row">

                    <b-button size="sm" @click="row.toggleDetails" variant="outline-primary">
                        {{ row.detailsShowing ? 'Hide' : 'Show' }}
                    </b-button>
                </template>
                <template #row-details="row">
                    <b-card>
                        <ul>
                            <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                        </ul>
                    </b-card>
                </template>

            </b-table>
        </b-card>
        <!-- <div class="row ">
            <div class="col-md-6">
                <b-card>
                </b-card>
            </div>
            <div class="col-md-6">
                <b-card>
                </b-card>
            </div>
        </div> -->

        <!-- </b-card> -->
        <!-- <b-card class="text-center shadow" no-body header="Administrasi PSB"> -->


        <!-- </b-card> -->

    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Datapsb",
    components: {
        //  Navbar,
    },
    data() {
        return {
            menupil: '',
            sortBy: 'nova',
            sortDesc: false,
            isBusy: false,
            summary: [],
            summary1: [],
            summary2: [],
            filterOn: ['nama'],
            filter: null,
            filteredCount: 0,
        };
    },
    mounted() {
        // this.refresh();
        // this.tg1 = this.$func.tglhariini();
        // this.tg2 = this.$func.tglhariini();

    },
    methods: {
        showimage(data) {
            window.open("https://newpsb.baiturrahman.com/php.script/" + data, "_blank")
        },
        onFiltered(filteredItems) {
            this.filteredCount = filteredItems.length;
        },
        hapus(para) {
            this.$bvModal
                .msgBoxConfirm("Yakin userid " + para + " mau diDELETE?", {
                    title: "Please Confirm",
                    size: "sm",
                    buttonSize: "sm",
                    okTitle: "YES",
                    cancelTitle: "NO",
                    noCloseOnBackdrop: true,
                })
                .then((value) => {
                    if (value) {
                        console.log('delelte :', para)
                    }
                })


        },
        approvestatus(para) {
            console.log('konfirm Status :', para)
            para.status = (para.status == '1') ? '2' : '1'
            axios
                .get(
                    this.$appUrl + "approvebayar.php?userid=" + para.userid + "&status=" + para.status + "&token=" +
                    this.$globalData.Token
                )
                .then((res) => {
                    console.log('proses', res.data)
                })

        },
        konfirmalumni(para) {
            console.log('konfirm alumni :', para)
            axios
                .get(
                    this.$appUrl + "approvealumni.php?userid=" + para.userid + "&konfirm=" + para.konfirmalumni + "&token=" +
                    this.$globalData.Token
                )
                .then((res) => {
                    console.log('proses', res)
                })

        },
        menu(para) {
            console.log("menu", "/adminpsb" + para)
            if (this.menupil != para) {
                this.menupil = para
                this.$router.push({ path: "/adminpsb" + para });
            }
        },
        refresh() {
            this.isBusy = true;
            this.summary1 = []
            axios
                .get(
                    this.$appUrl + "getresumepsb.php?jenis=1&token=" +
                    this.$globalData.Token
                )
                .then((res) => {
                    this.summary = res.data.summary;
                    this.summary1 = res.data.data1;
                    this.isBusy = false;
                    this.filteredCount = res.data.data1.length

                    // this.summary2 = res.data.data2;

                    // console.log('summary', this.summary)
                });
        },
    },
};
</script>

<style></style>
