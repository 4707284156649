<!-- 
level login:
1 : waliasrama/pmb
2 : kom dis
3 : kesehatan
4 : konseling BP
5
9 : admin
 -->
<template>
  <div id="app">
    <!-- <Menusidebar /> -->
    <b-navbar toggleable="lg" type="dark" variant="success" fixed="top" v-show="true" class="float-container">
      <!-- <div class="float"> -->
      <!-- <div class="float-container"> -->
      <h5>
        <b-icon-border-width style="cursor: pointer !important" class="mr-2 mt-2" @click="isCollapsed = !isCollapsed"
          v-if="false" />
        <i class="fa-solid fa-bars text-dark mr-2 mt-2" style="cursor: pointer !important"
          @click="isCollapsed = !isCollapsed" v-if="!$globalData.islogin"></i>
      </h5>

      <b-navbar-brand href="#">Baiturrahman Mobile<sup>
          <b-badge class="mt-0 ml-1">1.1
            <!-- {{ $globalData.versiaplikasi }} -->
          </b-badge></sup></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <li class="nav-item float-right">
            <div class="nav-link" id="Mypopover" @click="getnotes" style="cursor: pointer">
              {{ $globalData.TokenOK == 1 ? this.$globalData.UserId : "" }}

              |
              <b-badge variant="light"><span class="badge">Periode: {{ $globalData.CurrentYear }}</span></b-badge>
            </div>
          </li>
          <li class="nav-item">
            <a href="javascript:void(0)" @click="logout" class="nav-link">Logout</a>
          </li>
        </b-navbar-nav>
      </b-collapse>
      <!-- </div> -->
    </b-navbar>

    <!-- Sidebar -->
    <!-- <div class="row no-gutters"> -->
    <!-- {{ $globalData.hakuser }} {{ $func.cekhak($globalData.hakuser, "pmb") }},{{
      $func.cekhak($globalData.hakuser, "ass")
    }} -->

    <!-- Hakuser: {{ this.$globalData.datauser }} -->
    <!-- <b-button type="button" class="btn" @click="showModal"
      >Modal {{ showmodal }}</b-button
    > -->
    <div class="row">
      <div class="col m-0 p-0">
        <b-collapse v-model="isCollapsed" appear v-if="!$globalData.islogin">
          <div>
            <div class="col-auto bg-dark" style="margin-top: -8px; height: 100%">
              <!-- <div class="d-flex flex-column align-items-center text-center"> -->

              <div class="align-items-center text-center text-white">
                <!-- foto:{{
                  $globalData.pasfoto == null
                    ? "foto/avatar.png"
                    : $globalData.pasfoto
                }} -->

                <b-img :src="$globalData.datauser.pasfoto == null ||
                  $globalData.datauser.pasfoto == ''
                  ? $appUrl + 'foto/avatar.png'
                  : $appUrl + $globalData.datauser.pasfoto
                  " width="50%" @click="showprofiles" class="rounded-circle mt-2" />
                <div>
                  <h4>{{ $globalData.UserId }}</h4>
                  <p class="text-info mb-1">
                    {{ $globalData.LevelLogin == "9" ? "Admin" : "User" }}
                  </p>
                  <!-- <p class="text-muted font-size-sm">
                  Bay Area, San Francisco, CA
                </p> -->
                </div>
              </div>
              <hr class="bg-info" />
              <!-- <i class="bx bx-log-out" id="log_out"></i> -->
              <b-nav vertical class="col-auto ml-1 text-white" pills>
                <b-nav-item class="classhover" @click="menu('dashboardadmin')" v-show="$globalData.LevelLogin == '9'"
                  :active="$globalData.isactive == 'dashboardadmin'">
                  <!-- <i class="fa-solid fa-chart-bar mr-1"></i> -->
                  <i class="bi bi-speedometer2 mr-1"></i>
                  Dashboard Admin</b-nav-item>
                <b-nav-item class="classhover" @click="menu('dashboard')"
                  :active="$globalData.isactive == 'dashboard'"><i class="fa-solid fa-chart-bar mr-1"></i>

                  Dashboard</b-nav-item>
                <b-nav-item class="classhover" @click="menu('keuangan')" :active="$globalData.isactive == 'keuangan'"
                  v-show="$globalData.LevelLogin == '9' ||
                    $globalData.LevelLogin == '7'
                    "><i class="fa-regular fa-money-bill-1"></i>

                  Keuangan</b-nav-item>
                <b-nav-item class="classhover" @click="menu('datasiswa')" :active="$globalData.isactive == 'datasiswa'"
                  v-show="$globalData.LevelLogin == '9'"><i class="bi bi-people mr-1"></i>

                  Data Siswa</b-nav-item>
                <b-nav-item class="classhover" @click="menu('ngobras')" :active="$globalData.isactive == 'ngobras'"
                  v-show="$func.cekhak($globalData.hakuser, 'ass') ||
                    $globalData.LevelLogin == '9'
                    "><b-icon-mic class="mr-1" />Ngobras</b-nav-item>

                <b-nav-item class="classhover" @click="menu('konseling/bk')"
                  :active="$globalData.isactive == 'konseling/bk'" v-show="$globalData.LevelLogin == '4' ||
                    $globalData.LevelLogin == '9'
                    "><b-icon-mic class="mr-1" />Konseling BK</b-nav-item>
                <b-nav-item class="classhover" @click="menu('konseling/wl')"
                  :active="$globalData.isactive == 'konseling/wl'" v-show="$func.cekhak($globalData.hakuser, 'ass') ||
                    $globalData.LevelLogin == '9'
                    "><b-icon-mic-mute class="mr-1" />Konseling Wali</b-nav-item>
                <b-nav-item class="classhover" @click="menu('disiplin')" :active="$globalData.isactive == 'disiplin'"
                  v-show="$globalData.LevelLogin == '2' ||
                    $globalData.LevelLogin == '9'
                    "><b-icon-hand-thumbs-up class="mr-1" />Kom.Disiplin</b-nav-item>
                <b-nav-item class="classhover" @click="menu('pengobatan')"
                  :active="$globalData.isactive == 'pengobatan'" v-show="$globalData.LevelLogin == '3' ||
                    $globalData.LevelLogin == '9'
                    "><b-icon-thermometer-sun class="mr-1" />Kesehatan
                  UKS</b-nav-item>
                <b-nav-item class="classhover" @click="menu('penilaian')" :active="$globalData.isactive == 'penilaian'"
                  v-show="$func.cekhak($globalData.hakuser, 'ass') ||
                    $globalData.LevelLogin == '9'
                    "><b-icon-pencil-square class="mr-1" />Ibadah
                  Santri</b-nav-item>
                <b-nav-item class="classhover" @click="menu('murajaah')" :active="$globalData.isactive == 'murajaah'"
                  v-show="$func.cekhak($globalData.hakuser, 'mrj') ||
                    $globalData.LevelLogin == '9'
                    "><b-icon-speedometer class="mr-1" />Murojaah
                </b-nav-item>
                <b-nav-item class="classhover" @click="menu('aktivitaspmb')"
                  :active="$globalData.isactive == 'aktivitaspmb'" v-show="$func.cekhak($globalData.hakuser, 'pmb') ||
                    $globalData.LevelLogin == '9'
                    "><b-icon-ui-checks-grid class="mr-1" />Aktivitas
                  PMB</b-nav-item>
                <b-nav-item class="classhover" @click="menu('setting')" :active="$globalData.isactive == 'setting'"
                  v-show="$globalData.LevelLogin == '9'"><b-icon-gear class="mr-1" />Setting</b-nav-item>
                <b-nav-item class="classhover" @click="menu('setting2')" :active="$globalData.isactive == 'setting2'"
                  v-show="$globalData.LevelLogin == '9'"><b-icon-gear class="mr-1" />Pengaturan Entity</b-nav-item>
                <b-nav-item class="classhover" @click="menu('adminizin')" :active="$globalData.isactive == 'adminizin'"
                  v-show="$globalData.LevelLogin == '9'"><b-icon-file-lock class="mr-1" />Admin Perizinan</b-nav-item>

                <b-nav-item class="classhover" @click="menu('adminpsb')" :active="$globalData.isactive == 'adminpsb'"
                  v-show="$globalData.LevelLogin == '9' || $globalData.LevelLogin == '8'"><b-icon-circle-square
                    class="mr-1" />Admin PSB</b-nav-item>
                <!-- <b-nav-item class="classhover">Link</b-nav-item> -->
                <hr />
                <b-nav-item disabled></b-nav-item>
              </b-nav>
            </div>
          </div>
        </b-collapse>
        <!-- {{ $appUrl + "foto/pasfoto-test.jpg" }} -->
      </div>
      <div :class="{ 'col-md-10': isCollapsed, 'col-md-12': !isCollapsed }">
        <!-- level{{ $globalData.LevelLogin }} -->
        <router-view />
      </div>
    </div>

    <Profile message="modalMessage" v-model="showmodal" :form="deepCopy" />
    <!-- :form="$globalData.datauser" -->

    <Footer />
  </div>
</template>

<script>
import Footer from "./components/footer.vue";
import Profile from "./components/profile.vue";

// import Sidebar from "./components/sidebar.vue";

export default {
  name: "App",
  components: {
    Footer,
    Profile,
  },
  data() {
    return {
      isCollapsed: true,
      // isactive: "dashboard",
      showprofile: false,
      showmodal: false,
      isModalVisible: false,
      deepCopy: {},
    };
  },
  mounted() {
    this.$globalData.userId =
      localStorage.getItem("userid") === null
        ? ""
        : localStorage.getItem("userid");
    if (this.$globalData.islogin) {
      this.$router.push({ path: "/" });
    }
  },
  methods: {
    showprofiles() {
      console.log("Masuk Show Profile");
      this.deepCopy = JSON.parse(JSON.stringify(this.$globalData.datauser));
      this.showmodal = true;
    },
    getnotes() {
      if (this.$globalData.LevelLogin == "9") {
        this.$router.push({ path: "/mynotes" });
      }
    },
    logout() {
      this.$globalData.LoginOK = false;
      this.$globalData.TokenOK = 0;
      this.$globalData.LevelLogin = 0;
      this.$globalData.pasfoto = "";
      this.$globalData.islogin = true;
      this.$router.push({ path: "/" });
    },
    menu(para) {
      // console.log("para menu", para);
      if (this.$globalData.isactive != para) {
        // alert(para);
        this.$router.push({ path: "/" + para });
        this.$globalData.isactive = para;
      }
    },
    test() {
      var key = "dodolipete_bsw_asuransi";

      // Create an encryptor:
      var encryptor = require("simple-encryptor")(key);
      // To encrypt something:

      var encrypted = encryptor.encrypt("15600000");
      // Should print gibberish:
      console.log("encrypted: %s", encrypted);
      console.log;
      var decrypted = encryptor.decrypt(encrypted);
      // Should print 'testing'
      console.log("decrypted: %s", decrypted);
    },
  },
};
</script>

<style>
.classhover:hover {
  background-color: lightblue !important;
  color: white !important;
}
</style>
